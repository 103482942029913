import { useMainData } from "~/hooks";
import { Carousel } from "./Carousel";
import DtwImage from "./DtwImage";
import { Box } from "@mui/material";

/**
 * Shows a list of the store's trusted elements in the form of a paginated photo carousel.
 * It automatically deduces the number of pages required to show all the items on the device's screen size.
 */
export default function TrustCarousel() {
  const { trust } = useMainData();

  return (
    <Carousel itemsPerPage={{ xs: 4, md: 8 }}>
      {trust.map((t, index) => (
        <Box key={index} sx={{ height: 80, width: 80 }}>
          <DtwImage
            picture={t.picture}
            alt={t.alt}
            sx={{ width: 64, height: 64 }}
          />
        </Box>
      ))}
    </Carousel>
  );
}
