import type { Category } from "~/schemas";
import { Carousel } from "./Carousel";
import DtwImage from "./DtwImage";
import { Box, Typography, alpha } from "@mui/material";
import { Link } from "@remix-run/react";
import { useTextColorForSecondaryBg } from "~/hooks";

interface CategoryCarouselProps {
  categories: Category[];
}

/**
 * Carousel that displays the top-level categories for the store.
 */
export default function CategoryCarousel({
  categories,
}: CategoryCarouselProps) {
  const textColor = useTextColorForSecondaryBg();
  return (
    <Carousel itemsPerPage={{ xs: 1, md: 2, lg: 4 }}>
      {categories.map((c) => (
        <Link
          style={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
          key={c.id}
          to={`/categories${c.slug}`}
        >
          <Box
            sx={{
              width: 200,
              height: 356,
              display: "grid",
              placeContent: "center",
              placeItems: "center",
              gridTemplate: "container",
            }}
          >
            <DtwImage
              picture={c.picture}
              alt={c.title}
              sx={{ width: 200, height: 356, gridArea: "container" }}
            />
            <Box
              sx={{
                gridArea: "container",
                backgroundColor: (t) => alpha(t.palette.secondary.main, 0.8),
                width: 1,
                height: 1,
                borderRadius: 1,
              }}
            />
            <Typography
              sx={{ gridArea: "container", color: textColor }}
              align="center"
            >
              {c.title}
            </Typography>
          </Box>
        </Link>
      ))}
    </Carousel>
  );
}
